<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="580px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="getDataEstrella()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="tipo === 5">
                        <v-text-field
                            v-model="inventario.emisionCo2"
                            label="Emisiones CO2"
                            :error-messages="emisionesErrors"
                            @input="$v.inventario.emisionCo2.$touch()"
                            @blur="$v.inventario.emisionCo2.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field
                            v-model="certificacion.organismoValidacion"
                            label="Organismo de Validación"
                            :error-messages="orgValidacionErrors"
                            @input="$v.certificacion.organismoValidacion.$touch()"
                            @blur="$v.certificacion.organismoValidacion.$touch()"
                            counter="500"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-text-field
                            v-model="primerAlcance"
                            label="Primer Alcance"
                            :error-messages="primerAlcanceErrors"
                            @input="$v.primerAlcance.$touch()"
                            @blur="$v.primerAlcance.$touch()"
                            counter="250"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-text-field
                            v-model="segundoAlcance"
                            label="Segundo Alcance"
                            :error-messages="segundoAlcanceErrors"
                            @input="$v.segundoAlcance.$touch()"
                            @blur="$v.segundoAlcance.$touch()"
                            counter="250"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5 && anioConsulta > 2021">
                        <v-text-field
                            v-model="tercerAlcance"
                            label="Tercer Alcance"
                            :error-messages="tercerAlcanceErrors"
                            @input="$v.tercerAlcance.$touch()"
                            @blur="$v.tercerAlcance.$touch()"
                            counter="250"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="normativa"
                            label="Nombre de Reconocimiento"
                            :error-messages="normativaErrors"
                            @input="$v.normativa.$touch()"
                            @blur="$v.normativa.$touch()"
                            counter="300"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="tipo === 5">
                        <v-textarea
                            v-model="inventario.otrosDetalles"
                            :error-messages="otrosErrors"
                            @input="$v.inventario.otrosDetalles.$touch()"
                            @blur="$v.inventario.otrosDetalles.$touch()"
                            counter="500"
                            label="Otros detalles">
                        </v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            error-messages="fechaErrors"
                            @input="$v.fechaCuantificacion.$touch()"
                            @blur="$v.fechaCuantificacion.$touch()"
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Cuantificación"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="fechaCuantificacion"
                                locale="es-CR"
                                @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarEstrella">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, requiredIf, decimal, maxLength } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fechaMoment() {
            return this.fechaCuantificacion ? moment(this.fechaCuantificacion).format("DD/MM/YYYY") : "";
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        emisionesErrors() {
            const errors = [];
            if (!this.$v.inventario.emisionCo2.$dirty) return errors;
            !this.$v.inventario.emisionCo2.required && errors.push("Las emisiones de CO2 son requeridas.")
            !this.$v.inventario.emisionCo2.decimal && errors.push("Solo debe digitar números.")
            return errors;
        },
        orgValidacionErrors() {
            const errors = [];
            if (!this.$v.certificacion.organismoValidacion.$dirty) return errors;
            !this.$v.certificacion.organismoValidacion.required && errors.push("El organismo de validación es requerido.")
            !this.$v.certificacion.organismoValidacion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors;
        },
        normativaErrors() {
            const errors = [];
            if (!this.$v.normativa.$dirty) return errors;
            !this.$v.normativa.required && errors.push("Campo requerido.")
            !this.$v.normativa.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors;
        },
        fechaErrors() {
            const errors = [];
            if (!this.$v.fechaCuantificacion.$dirty) return errors;
            !this.$v.fechaCuantificacion.required && errors.push("La fecha de la cuantificación es requerida.")
            return errors;
        },
        otrosErrors() {
            const errors = []
            if (!this.$v.inventario.otrosDetalles.$dirty) return errors            
            !this.$v.inventario.otrosDetalles.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        primerAlcanceErrors() {
            const errors = []
            if (!this.$v.primerAlcance.$dirty) return errors            
            !this.$v.primerAlcance.required && errors.push('El alcance es requerido.')
            !this.$v.primerAlcance.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        segundoAlcanceErrors() {
            const errors = []
            if (!this.$v.segundoAlcance.$dirty) return errors            
            !this.$v.segundoAlcance.required && errors.push('El alcance es requerido.')
            !this.$v.segundoAlcance.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        tercerAlcanceErrors() {
            const errors = []
            if (!this.$v.tercerAlcance.$dirty) return errors            
            !this.$v.tercerAlcance.required && errors.push('El alcance es requerido.')
            !this.$v.tercerAlcance.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    props: ["tipo", "titulo", "id"],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        inventario: {
            emisionCo2: "",
            otrosDetalles: '',
        },
        certificacion: { organismoValidacion: "" },
        normativa: "",
        fechaCuantificacion: new Date().toISOString().substr(0, 10),
        primerAlcance: '',
        segundoAlcance: '',
        tercerAlcance: '',
    }),
    validations: {
        inventario: {
            emisionCo2: {
                required: requiredIf(function() {
                    return this.tipo === 5;
                }),
                decimal
            },
            otrosDetalles: {
                maxLength: maxLength(500)
            }
        },
        certificacion: {
            organismoValidacion: {
                maxLength: maxLength(500),
                required: requiredIf(function() {
                    return this.tipo === 6;
                })
            }
        },
        normativa: { required, maxLength: maxLength(300) },
        fechaCuantificacion: { required },
        primerAlcance: { maxLength: maxLength(250), required: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
        segundoAlcance: { maxLength: maxLength(250), required: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
        tercerAlcance: { maxLength: maxLength(250), required: requiredIf(function(){
            return this.anioConsulta > 2021 && this.tipo === 5;
        })},
    },
    methods: {
        dateFormat(fecha) {
            return new Date(fecha).toISOString().substr(0, 10);
        },
        cancelar() {
            this.inventario = {};
            this.certificacion = {};
            this.normativa = '';
            this.fechaCuantificacion = new Date().toISOString().substr(0, 10);
            this.dialog = false;
            this.loading = false;
            this.$v.inventario.$reset();
            this.$v.certificacion.$reset();
            this.$v.normativa.$reset();
            this.$v.primerAlcance.$reset();
            this.$v.segundoAlcance.$reset();
            this.$v.tercerAlcance.$reset();
        },
        getDataEstrella() {
            this.loading = true;
            if (this.tipo === 5)
                this.getInventarioEmision();
            else
                this.getCertificacionCarbono();
        },
        getInventarioEmision() {
            axios.get("/estrellas/getInventarioGei/"+this.id)
            .then(response => {
                this.inventario = response.data.inventario;   
                this.fechaCuantificacion = this.dateFormat(response.data.inventario.fechaCuantificacion);
                this.normativa = response.data.inventario.normativaReferencia;
                this.primerAlcance = response.data.inventario.primerAlcance;
                this.segundoAlcance = response.data.inventario.segundoAlcance;
                this.tercerAlcance = response.data.inventario.tercerAlcance;                 
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        getCertificacionCarbono() {
            axios.get("/estrellas/getCertificadoCarbono/"+this.id)
            .then(response => {
                this.certificacion = response.data.certificado;   
                this.fechaCuantificacion = this.dateFormat(response.data.certificado.fechaCuantificacion);
                this.normativa = response.data.certificado.normativaReferencia;                 
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                if (this.tipo === 5)
                    this.modificaInventarioEmision();
                else
                    this.modificaCertificacionCarbono();
            }
        },
        modificaInventarioEmision() {
            let inventario = this.inventario;
            delete inventario.docEvidencia;
            axios.put("/estrellas/modificarInventarioGei", inventario, { params: {
                idUsuario: this.idUsuario,
                normativa: this.normativa,
                fecha: this.fechaCuantificacion,
                primerAlcance: this.primerAlcance,
                segundoAlcance: this.segundoAlcance,
                tercerAlcance: this.tercerAlcance,
            }}).then(response => {
                this.$emit("recargaTabla");
                this.$store.dispatch("noti", { mensaje: "Emisión de GEI modificada", color: "success" });
                this.cancelar();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al modificar la emisión " + error);
                this.$store.dispatch("noti", { 
                    mensaje: "Ocurrió un error no se modificó la emisión de GEI",
                    color: "error"
                });
                this.cancelar();
              }
            });
        },
        modificaCertificacionCarbono() {
            let certificacion = this.certificacion;
            delete certificacion.docEvidencia;
            axios.put("/estrellas/modificarCertificadoCarbono", certificacion, { params: {
                idUsuario: this.idUsuario,
                normativa: this.normativa,
                fecha: this.fechaCuantificacion,
            }}).then(response => {
                this.$emit("recargaTabla");
                this.$store.dispatch("noti", { mensaje: "Certificado de carbono neutralidad modificado", color: "success" });
                this.cancelar();
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al modificar el certificado " + error);
                this.$store.dispatch("noti", { 
                    mensaje: "Ocurrió un error no se modificó el certificado de carbono neutralidad",
                    color: "error"
                });
                this.cancelar();
              }
            });
        },
    }
};
</script>
